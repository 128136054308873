import StateCMS, { DragDropAssetData, DragDropComponentData } from './types'

export default {
  moduleId: null!,
  content: null!,
  serverContent: null!,
  sidebarExpandedItems: null! as (string | number)[],
  selectedSiteId: undefined,
  zoom: 1.0,
  device: null!,
  orientation: 'portrait',
  dark: null!,
  editMode: false,
  translateMode: false,
  assetPanelView: 'grid',
  isTest: false,

  selectedComponentId: null!,
  masterLanguageCode: null!,
  currentLanguageCode: null!,

  draggedItemIdentifier: null! as string,
  draggedItemDropSuccessful: false,
  kickedOutOfDropZoneItem: null! as string,

  sidebarActive: undefined,
  assetPanelOpen: false,

  userInput: {},
  activeComponentIdsWithUserInput: [],

  _hoveredTreeComponentId: null!,
  _openedTreeComponentIds: [],

  _isDirty: false,
  _isContentUpdating: false,

  _dragDropComponentData: null! as DragDropComponentData,
  _dragDropAssetData: null! as DragDropAssetData,
  _dragDropFileCount: 0,
  _dragDropFileType: null! as string,

  _contentDraggingDisabled: false,

  _isUploading: false,

  _dropSuccessful: false,

  _structureChapterDragActive: false,

  _componentMapping: {},

  _showPointerBlock: false,

  _loading: 0
} as StateCMS
