import { GetterTree } from 'vuex'
import StateCMS from './types'
import { getTreeContent } from '../utils/tree-operations'

export default {
  selectedContent: (state) => {
    if (state.selectedSiteId === null || typeof state.selectedSiteId === 'undefined' || !state.content) return null

    return getTreeContent(state.content, state.selectedSiteId)
  },
  selectedComponent: (state) => {
    if (!state.selectedComponentId) return null

    return state._componentMapping[state.selectedComponentId]
  },
  activeComponentsCompleted: state => {
    let allCompleted = true

    state.activeComponentIdsWithUserInput.forEach(id => {
      if (!state.userInput[id]?.completed) allCompleted = false
    })

    return allCompleted
  },
  activeComponentsCorrect: state => {
    let allCorrect = true

    state.activeComponentIdsWithUserInput.forEach(id => {
      if (!state.userInput[id]?.correct) allCorrect = false
    })

    return allCorrect
  },
  activeComponentsPoints: state => {
    let points = 0

    state.activeComponentIdsWithUserInput.forEach(id => {
      if (state.userInput[id]?.points) {
        points += state.userInput[id]!.points!
      }
    })

    return points
  }
} as GetterTree<StateCMS, any>
