import { ContentTree } from '../types'

export default function normalizeTree (tree: Record<string, any>): ContentTree {
  const copy = { ...tree }
  copy.id = parseInt(tree.id)

  if (typeof tree.data === 'string') {
    copy.data = JSON.parse(tree.data)
  }

  copy.children = []
  for (let i = 0; i < tree.children.length; i++) {
    const child = tree.children[i]
    copy.children.push(normalizeTree(child))
  }

  return copy as any
}
