import { MutationTree } from 'vuex'
import StateCMS from './types'
import { MUTATIONS } from './consts'
import { ContentTree } from '../types'
import Vue from 'vue'
import normalizeTree from '../utils/normalize-tree'
import patchTree from '../utils/patch-tree'

export default {
  [MUTATIONS.SET_MODULE_ID]: (state, payload) => {
    state.moduleId = payload
  },
  [MUTATIONS.PATCH_CONTENT]: (state, payload) => {
    if (payload) {
      const normalizedServerContent = normalizeTree(payload)
      patchTree(state.content, normalizedServerContent)

      const serverContent = findAndReplaceSubtree(state.serverContent, normalizedServerContent)
      state.serverContent = JSON.parse(JSON.stringify(serverContent))
    }
  },
  [MUTATIONS.SET_CONTENT]: (state, payload) => {
    if (payload.online) {
      const copy = payload.content ? JSON.parse(JSON.stringify(payload.content)) : null
      state.content = normalizeTree(copy)
      state.serverContent = JSON.parse(JSON.stringify(state.content))
    } else {
      state.content = payload
    }
  },
  [MUTATIONS.SET_SERVER_CONTENT]: (state, payload) => {
    const copy = JSON.parse(JSON.stringify(payload))
    state.serverContent = normalizeTree(copy)
  },
  [MUTATIONS.SET_SIDEBAR_EXPANDED_ITEMS]: (state, payload) => {
    state.sidebarExpandedItems = (payload && Array.isArray(payload)) ? payload.slice() : []
  },
  [MUTATIONS.SET_LOADING_VALUE]: (state, payload) => {
    state._loading = payload
  },
  [MUTATIONS.INCREASE_LOADING_VALUE]: (state, payload) => {
    state._loading += (payload || 1)
  },
  [MUTATIONS.DECREASE_LOADING_VALUE]: (state, payload) => {
    state._loading -= (payload || 1)
  },
  [MUTATIONS.SET_SELECTED_SITE_ID]: (state, payload) => {
    state.selectedSiteId = payload
  },
  [MUTATIONS.SET_ZOOM]: (state, payload) => {
    state.zoom = payload
  },
  [MUTATIONS.SET_DEVICE]: (state, payload) => {
    state.device = payload
  },
  [MUTATIONS.SET_ORIENTATION]: (state, payload) => {
    state.orientation = ['portrait', 'landscape'].includes(payload) ? payload : 'portrait'
  },
  [MUTATIONS.SET_ASSET_PANEL_VIEW]: (state, payload) => {
    state.assetPanelView = ['grid', 'list'].includes(payload) ? payload : 'grid'
  },
  [MUTATIONS.SET_SIDEBAR_ACTIVE]: (state, payload) => {
    state.sidebarActive = payload
  },
  [MUTATIONS.SET_ASSET_PANEL_OPEN]: (state, payload) => {
    state.assetPanelOpen = payload
  },
  [MUTATIONS.SET_TEST]: (state, payload) => {
    state.isTest = payload
  },
  [MUTATIONS.SET_THEME]: (state, payload) => {
    state.dark = payload
  },
  [MUTATIONS.SET_SELECTED_COMPONENT_ID]: (state, payload) => {
    state.selectedComponentId = payload
  },
  [MUTATIONS.SET_DRAGGED_ITEM_IDENTIFIER]: (state, payload) => {
    state.draggedItemIdentifier = payload
  },
  [MUTATIONS.SET_DRAGGED_ITEM_DROP_SUCCESSFUL]: (state, payload) => {
    state.draggedItemDropSuccessful = payload
  },
  [MUTATIONS.SET_KICKED_OUT_OF_DROP_ZONE_ITEM]: (state, payload) => {
    state.kickedOutOfDropZoneItem = payload
  },
  [MUTATIONS.SET_COMPONENT_MAPPING_VALUE]: (state, { id, instance }) => {
    if (!instance) {
      Vue.delete(state._componentMapping, id)
    } else {
      Vue.set(state._componentMapping, id, instance)
    }
  },
  [MUTATIONS.RESET_COMPONENT_MAPPING]: (state) => {
    state._componentMapping = {}
  },

  [MUTATIONS.SET_DIRTY]: (state, payload) => {
    state._isDirty = !!payload
  },
  [MUTATIONS.SET_CONTENT_UPDATING]: (state, payload) => {
    state._isContentUpdating = !!payload
  },
  [MUTATIONS.SET_OPENED_TREE_COMPONENT_ID]: (state, payload) => {
    state._openedTreeComponentIds = payload
  },
  [MUTATIONS.SET_HOVERED_TREE_COMPONENT_ID]: (state, payload) => {
    state._hoveredTreeComponentId = payload
  },
  [MUTATIONS.SET_STRUCTURE_CHAPTER_DRAG_ACTIVE]: (state, payload) => {
    state._structureChapterDragActive = !!payload
  },
  [MUTATIONS.SET_DRAG_DROP_COMPONENT_DATA]: (state, payload) => {
    state._dragDropComponentData = payload
  },
  [MUTATIONS.SET_DRAG_DROP_ASSET_DATA]: (state, payload) => {
    state._dragDropAssetData = payload
  },
  [MUTATIONS.SET_DRAG_DROP_FILE_COUNT]: (state, payload) => {
    state._dragDropFileCount = payload
  },
  [MUTATIONS.SET_DRAG_DROP_FILE_TYPE]: (state, payload) => {
    state._dragDropFileType = payload
  },
  [MUTATIONS.SET_CONTENT_DRAGGING_DISABLED]: (state, payload) => {
    state._contentDraggingDisabled = payload
  },
  [MUTATIONS.SET_UPLOAD_ACTIVE]: (state, payload) => {
    state._isUploading = !!payload
  },
  [MUTATIONS.SET_EDIT_MODE]: (state, payload) => {
    state.editMode = payload
  },
  [MUTATIONS.SET_TRANSLATE_MODE]: (state, payload) => {
    state.translateMode = payload
  },
  [MUTATIONS.SET_DROP_SUCCESSFUL]: (state, payload) => {
    state._dropSuccessful = payload
  },
  [MUTATIONS.SET_POINTER_BLOCK_OVERLAY_VISIBLE]: (state, payload) => {
    state._showPointerBlock = payload
  },
  [MUTATIONS.SET_USER_INPUT]: (state, { id, input }) => {
    state.userInput = {
      ...state.userInput,
      [id]: input
    }
  },
  [MUTATIONS.RESET_USER_INPUT]: (state) => {
    state.userInput = {}
  },
  [MUTATIONS.SET_MASTER_LANGUAGE_CODE]: (state, payload) => {
    state.masterLanguageCode = payload
  },
  [MUTATIONS.SET_CURRENT_LANGUAGE_CODE]: (state, payload) => {
    state.currentLanguageCode = payload
  },
  [MUTATIONS.REGISTER_ACTIVE_COMPONENT_ID]: (state, payload) => {
    if (state.activeComponentIdsWithUserInput.indexOf(payload) > -1) return

    state.activeComponentIdsWithUserInput.push(payload)
  },
  [MUTATIONS.UNREGISTER_ACTIVE_COMPONENT_ID]: (state, payload) => {
    const index = state.activeComponentIdsWithUserInput.indexOf(payload)
    if (index < 0) return

    state.activeComponentIdsWithUserInput.splice(index, 1)
  },
  [MUTATIONS.UNREGISTER_ALL_ACTIVE_COMPONENT_IDS]: (state) => {
    state.activeComponentIdsWithUserInput = []
  }
} as MutationTree<StateCMS>

function findAndReplaceSubtree (serverContent: ContentTree, subTree: ContentTree): ContentTree {
  function findSubTreeParent (tree: ContentTree, parent?: ContentTree): ContentTree | undefined | null {
    if (String(tree.id) === String(subTree.id)) return parent

    for (const child of tree.children || []) {
      const childResult = findSubTreeParent(child, tree)
      if (childResult) return childResult
    }

    return null
  }

  const parent = findSubTreeParent(serverContent)

  if (!parent) { // Root has changed
    return subTree
  }

  const childPos = parent.children.findIndex(c => String(c.id) === String(subTree.id))
  parent.children.splice(childPos, 1, subTree)
  return serverContent
}
