import { ContentTree } from '../types'

export function getTreeContent (root: ContentTree, id: number | string): ContentTree | undefined | null {
  if (root.id === id) return root

  if (!root.children) return null

  for (let i = 0; i < root.children.length; i++) {
    const match = getTreeContent(root.children[i], id)
    if (match) return match
  }
}

export function getTreeContentParent (root: ContentTree, id: number | string): ContentTree | undefined | null {
  if (root.children.map(c => String(c.id)).includes(String(id))) return root

  if (!root.children) return null

  for (let i = 0; i < root.children.length; i++) {
    const match = getTreeContentParent(root.children[i], id)
    if (match) return match
  }
}
