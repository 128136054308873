import { ActionTree } from 'vuex'
import StateCMS from './types'
import { ACTIONS, MUTATIONS } from './consts'
import { app } from '@simpl/core/init-app'
import { GET_CONTENT_TREE } from '../graphql'

export default {
  [ACTIONS.FETCH_CONTENT]: async ({ commit, state }) => {
    commit(MUTATIONS.INCREASE_LOADING_VALUE)

    const result = await app.$apollo.query({
      query: GET_CONTENT_TREE,

      fetchPolicy: 'no-cache',

      variables: {
        moduleId: state.moduleId
      }
    })

    commit(MUTATIONS.DECREASE_LOADING_VALUE)

    commit(
      MUTATIONS.SET_CONTENT, {
        online: true,
        content: result.data.contentTree || null
      }
    )
  },
  [ACTIONS.FETCH_AND_PATCH_CONTENT]: async ({ commit, state }) => {
    commit(MUTATIONS.INCREASE_LOADING_VALUE)

    const result = await app.$apollo.query({
      query: GET_CONTENT_TREE,

      fetchPolicy: 'no-cache',

      variables: {
        moduleId: state.moduleId
      }
    })

    commit(MUTATIONS.DECREASE_LOADING_VALUE)

    commit(MUTATIONS.PATCH_CONTENT, result.data?.updateContentTree || result.data.contentTree)
  }
} as ActionTree<StateCMS, any>
