import { ContentTree } from '../types'
import { getTreeContent } from './tree-operations'
import store from '@simpl/core/plugins/store'
import { MUTATIONS } from '../store/consts'

export default function patchTree (content: ContentTree, patchRoot: ContentTree): void {
  const parent = getTreeContent(content, patchRoot.id)

  _patchTree(parent || undefined, patchRoot)
}

function _patchTree (local?: ContentTree, remote?: ContentTree): void {
  if (!local && remote) {
    console.warn('Help 1!')
    return
  }

  if (!remote && local) {
    console.warn('Help 2!')
    return
  }

  if (local!.isLocal) {
    delete local!.isLocal
    const localId = local!.id
    local!.id = remote!.id

    if (store.state.cms.selectedComponentId === localId) {
      store.commit(`cms/${MUTATIONS.SET_SELECTED_COMPONENT_ID}`, remote!.id)
    }

    if (store.state.cms.selectedSiteId === localId) {
      store.commit(`cms/${MUTATIONS.SET_SELECTED_SITE_ID}`, remote!.id)
    }
  }

  const data = remote?.data ? remote.data : null
  const properties = remote?.properties ? remote.properties : null

  if (data) {
    if (local?.data?._changedByUser) {
      local!.data = { ...data, ...(local!.data || {}) }
      delete local!.data!._changedByUser
    } else {
      local!.data = { ...(local!.data || {}), ...data }
    }
  }
  if (properties) local!.properties = { ...properties, ...(local!.properties || {}) }

  const remoteTexts = remote!.texts || []
  if (remoteTexts.length && !local!._textChangedByUser) {
    local!.texts = [...remoteTexts]
  } else {
    if (local!._textChangedByUser) {
      delete local!._textChangedByUser
    }
  }

  const localChildren = local!.children
  const remoteChildren = remote!.children

  if (local!._changedByUser) {
    delete local!._changedByUser
  } else if (!localChildren.length) {
    addChildDiffs(localChildren, 0, remoteChildren.length - 1, remoteChildren)
  } else if (!remoteChildren.length) {
    removeChildren(localChildren, 0, localChildren.length - 1)
  } else {
    patchChildren(localChildren, remoteChildren)
  }
}

function createKeyToOldIdx (
  children: ContentTree[],
  beginIdx: number,
  endIdx: number
): Record<any, any> {
  let i
  let key
  const map: Record<any, any> = {}
  for (i = beginIdx; i <= endIdx; ++i) {
    key = children[i]?.id
    if (!isUndefined(key)) map[key] = i
  }
  return map
}

function patchChildren (oldCh: ContentTree[], newCh: ContentTree[]) {
  let oldStartIdx = 0
  let newStartIdx = 0
  let oldEndIdx = oldCh.length - 1
  let oldStartContent = oldCh[0]
  let oldEndContent = oldCh[oldEndIdx]
  let newEndIdx = newCh.length - 1
  let newStartContent = newCh[0]
  let newEndContent = newCh[newEndIdx]
  let contentToMove: ContentTree
  let oldKeyToIdx: Record<any, any> = undefined!
  let idxInOld

  while (oldStartIdx <= oldEndIdx && newStartIdx <= newEndIdx) {
    if (isUndefined(oldStartContent)) {
      oldStartContent = oldCh[++oldStartIdx] // Content has been moved left
    } else if (isUndefined(oldEndContent)) {
      oldEndContent = oldCh[--oldEndIdx]
    } else if (sameContent(oldStartContent, newStartContent)) {
      _patchTree(oldStartContent, newStartContent)

      oldStartContent = oldCh[++oldStartIdx]
      newStartContent = newCh[++newStartIdx]
    } else if (sameContent(oldEndContent, newEndContent)) {
      _patchTree(oldEndContent, newEndContent)

      oldEndContent = oldCh[--oldEndIdx]
      newEndContent = newCh[--newEndIdx]
    } else if (sameContent(oldStartContent, newEndContent)) { // Content moved right
      _patchTree(oldStartContent, newEndContent)

      oldStartContent = oldCh[++oldStartIdx]
      newEndContent = newCh[--newEndIdx]
    } else if (sameContent(oldEndContent, newStartContent)) { // Content moved left
      _patchTree(oldEndContent, newStartContent)

      oldEndContent = oldCh[--oldEndIdx]
      newStartContent = newCh[++newStartIdx]
    } else {
      if (isUndefined(oldKeyToIdx)) oldKeyToIdx = createKeyToOldIdx(oldCh, oldStartIdx, oldEndIdx)
      idxInOld = oldKeyToIdx[newStartContent.id]

      if (!isUndefined(idxInOld)) {
        contentToMove = oldCh[idxInOld]
        if (sameContent(contentToMove, newStartContent)) {
          oldCh[idxInOld] = undefined!
          _patchTree(contentToMove, newStartContent)
        }
      }

      newStartContent = newCh[++newStartIdx]
    }

    if (oldStartIdx > oldEndIdx) {
      addChildDiffs(oldCh, newStartIdx, newEndIdx, newCh)
    } else if (newStartIdx > newEndIdx) {
      removeChildren(oldCh, oldStartIdx, oldEndIdx)
    }
  }
}

function addChildDiffs (
  target: ContentTree[],
  startIdx: number, endIdx: number,
  ch: ContentTree[]
) {
  for (; startIdx <= endIdx; ++startIdx) {
    target.splice(startIdx, 0, ch[startIdx])
  }
}

function removeChildren (
  target: ContentTree[],
  startIdx: number, endIdx: number
) {
  target.splice(startIdx, endIdx - startIdx + 1)
}

function isUndefined (v: any): v is undefined {
  // eslint-disable-next-line
  return v === void 0
}

function sameContent (o: ContentTree, v: ContentTree) {
  return (String(o.id) === String(v.id) || o.isLocal !== v.isLocal)
}
